<template>
    <div :style="`height: ${height}px`" id="list">
        <div class="container">
            <div class="nav" v-if="balanceInfo">
                <div>
                    <span>剩余金额</span>
                    <span style="font-size: 24px;margin-top: 3px">￥{{ balanceInfo.redBalance }}</span>
                </div>
                <div class="wallet_box_haved">
                    <span>已使用</span>
                    <span style="font-size: 24px;margin-top: 3px">￥{{ balanceInfo.redUsedBalance }}</span>
                </div>
                <div class="line"></div>
            </div>

            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div v-for="(item,index) in datalist" :key="index" class="list_item">
                    <div class="list_item_top">
                        <div class="list_item_num">{{ item.typeStr }}{{item.orderNumStr}}</div>
                        <div class="list_item_price" v-if="item.incomeType === 1">+{{item.redBalance}}</div>
                        <div class="list_item_price font-green" v-else>-{{item.redBalance}}</div>
                    </div>
                    <div class="list_item_time">
                        <div>{{item.startTime}}</div>
                        <div v-if="item.endTime">{{item.endTime}}到期</div>
                    </div>
                    <img v-if="item.state == 2" style="position: absolute;right: 0;bottom: 0;width: 60px;height: 50px"
                         src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210508112957930.png" alt="">
                    <img v-if="item.state == 3" style="position: absolute;right: 0;bottom: 0;width: 60px;height: 50px"
                         src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210508113157212.png" alt="">
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
  export default {
    name: "list",
    data() {
      return {
        height: '',
        loading: false,
        finished: false,
        ps: 10,
        pg: 0,
        datalist: [],
        balanceInfo: null
      }
    },
    created() {
      this.height = document.documentElement.clientHeight;
      this.getBalanceInfo();
    },
    methods: {
      onLoad() {
        this.pg++;
        this.$toast.loading({message: '加载中...', duration: 0, overlay: true});
        this.get("/OrderGoods/FixedExchange/GetBuyRedList", {
          start: this.pg, length: this.ps
        }, 2).then(res => {
          this.$toast.clear();
          if (res.code === 1) {
            let list = res.response;
            list.forEach(it => {
              it.orderNumStr = (() => {
                let arr = [];
                it.orderNumList.forEach(num => {
                  arr.push('【' + num + '】')
                })
                return arr.join("+")
              })();
            });
            if (list.length < this.ps) this.finished = true;
            this.datalist = this.datalist.concat(list);
          } else {
            this.$toast(res.msg);
          }
          this.loading = false
        });
      },
      //获取余额红包信息
      getBalanceInfo() {
        this.get('/OrderGoods/FixedExchange/GetRedBalance', {}, 2).then(json => {
          if (json.code === 1) {
            this.balanceInfo = json.response || {};
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
    #list {
        background-color: #C57DF3;
        /*background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210331100413896.png");*/
        /*background-repeat: no-repeat;*/
        /*background-position: 0 0;*/
        /*background-size: 100%;*/
        overflow: hidden;

        .container {
            background-color: #fff;
            width: calc(100% - 30px);
            height: calc(100% - 20px);
            margin-top: 20px;
            margin-left: 15px;
            margin-right: 15px;
            border-radius: 10px 10px 0 0;
            overflow-y: scroll;
            position: relative;
            padding-top: 80px;
            /*padding-bottom: 40px;*/
            /*display: flex;*/
            /*justify-content: space-between;*/
            .nav {
                position: fixed;
                width: calc(100% - 30px);
                top: 20px;
                border-radius: 10px 10px 0 0;
                left: 50%;
                transform: translateX(-50%);
                padding: 15px 50px;
                box-sizing: border-box;
                border-bottom: 1px solid #F1F1F1;
                background-color: #fff;
                z-index: 1;
                display: flex;
                justify-content: space-between;

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #FF48A4;
                }

                .wallet_box_haved {
                    color: #F1A000;
                }

                .line {
                    height: 40px;
                    width: 2px;
                    background-color: #F1F1F1;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .van-list {
                padding-bottom: 100px;
            }

            .list_item {
                margin-top: 15px;
                margin-left: 15px;
                padding-bottom: 15px;
                position: relative;
                border-bottom: 1px solid #F1F1F1;

                .list_item_top {
                    display: flex;
                    justify-content: space-between;

                    .list_item_num {
                        word-break: break-all;
                    }

                    .list_item_price {
                        color: #FF3E6E;
                        font-size: 16px;
                        font-weight: bold;
                        margin-right: 5px;

                        &.font-green {
                            color: #000;
                        }
                    }
                }

                .list_item_time {
                    margin-top: 10px;
                    align-items: center;
                    display: flex;

                    div {
                        color: #999999;
                        margin-left: 5px;

                        &:nth-child(1) {
                            margin-right: 10px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
        }
    }
</style>